import AppConsts from './../lib/appconst'; 
import { Modal } from 'antd';
import axios from 'axios';

 

declare var abp: any;

const http = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 30000,
  
});

http.defaults.headers.common['.AspNetCore.Culture'] = abp.utils.getCookieValue('Abp.Localization.CultureName');
http.defaults.headers.common['Abp.TenantId'] = abp.multiTenancy.getTenantIdCookie();
http.defaults.headers.common['Authorization'] = 'Bearer ' + abp.auth.getToken();

http.interceptors.request.use(

  function(config: any) {
  
    //if (!!abp.auth.getToken()) {
    //  config.headers.common['Authorization'] = 'Bearer ' + abp.auth.getToken();
    //}
 
    //config.headers.common['.AspNetCore.Culture'] = abp.utils.getCookieValue('Abp.Localization.CultureName');
    //config.headers.common['Abp.TenantId'] = abp.multiTenancy.getTenantIdCookie();

    return config;
  },
  
  function(error) {
    return Promise.reject(error);
  }
  
);

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
 
    if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
      Modal.error({
        title: error.response.data.error.message,
        content: error.response.data.error.details,
      });
    } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
      Modal.error({
        title: error.response.data.error.message,
        content: error.response.data.error.response,
      });
    } else if (!error.response) {
      Modal.error({ content: error.toString() });
    }

    setTimeout(() => {}, 1000); 
    return Promise.reject(error);
  }
);

export default http;
