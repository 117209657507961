import { action, observable } from 'mobx';
import type { AreaChart_Configuration, AreaChart_DataModel, AxisModel } from '../components/DataModels/areaChart_Configuration'
import type { BarChart_Configuration, BarChart_DataModel } from '../components/DataModels/barChart_Configuration';
import type { BoxPlot_Configuration, BoxPlot_DataModel, BoxStyleModel } from '../components/DataModels/boxPlot_Configuration';
import type { Bullet_Configuration, Bullet_DataModel } from '../components/DataModels/bullet_Configuration';
import type { CirclePacking_Configuration, CirclePacking_DataModel, CirclePacking_DataModelChildren } from '../components/DataModels/circlePacking_Configuration';
import type { ColumnGraph_Configuration, ColumnGraph_DataModel } from '../components/DataModels/columnGraph_Configuration';
import type { DonutChart_Configuration, DonutChart_DataModel } from '../components/DataModels/donutChart_Configuration';
import type { FunnelChart_Configuration, FunnelChart_DataModel } from '../components/DataModels/funnelChart_Configuration';
import type { Gauge_Configuration } from '../components/DataModels/gauge_Configuration';
import type { HeatMap_Configuration, HeatMap_DataModel } from '../components/DataModels/heatMap_Configuration';
import type { Histogram_Configuration, Histogram_DataModel } from '../components/DataModels/histogram_Configuration';
import type { LineChart_Configuration, LineChart_DataModel } from '../components/DataModels/lineChart_Configuration';
import type { Liquid_Configuration } from '../components/DataModels/liquid_Configuration';
import type { MultiLineChart_Configuration, MultiLineChart_DataModel } from '../components/DataModels/multiLineChart_Configuration';
import type { PieChart_Configuration, PieChart_DataModel } from '../components/DataModels/pieChart_Configuration';
import type { Progress_Configuration } from '../components/DataModels/progress_Configuration';
import type { RadarChart_Configuration } from '../components/DataModels/radarChart_Configuration';
import type { RadialBarChart_Configuration, RadialBarChart_DataModel } from '../components/DataModels/radialBarChart_Configuration';
import type { Ring_Configuration } from '../components/DataModels/ring_Configuration';
import type { RoseChart_Configuration, RoseChart_DataModel } from '../components/DataModels/roseChart_Configuration';
import type { SankeyPlot_Configuration, SankeyPlot_DataModel } from '../components/DataModels/sankeyPlot_Configuration';
import type { ScatterPlot_Configuration, ScatterPlot_DataModel } from '../components/DataModels/scatterPlot_Configuration';
import type { StockPlot_Configuration, StockPlot_DataModel } from '../components/DataModels/stockPlot_Configuration';
import type { SunburstPlot_Configuration, SunburstPlot_DataModel } from '../components/DataModels/sunburstPlot_Configuration';
import type { VennPlot_Configuration, VennPlot_DataModel } from '../components/DataModels/vennPlot_Configuration';
import type { ViolinPlot_Configuration, ViolinPlot_DataModel } from '../components/DataModels/violinPlot_Configuration';
import type { WaterfallChart_Configuration, WaterfallChart_DataModel } from '../components/DataModels/waterfallChart_Configuration';
import { DashboardItemTypes } from '../components/EntityConfigs/dashboardItemTypes';
import dashboardService from '../services/dashboard/dashboardService';
import type { DashbordAskDto } from '../services/dashboard/dto/dashboardAskDto';
import type { DashboardLayoutDto, DashboardRowDto, DashboardColumnDto } from '../services/dashboard/dto/dashboardLayoutDto';


class DashboardStore {

    @observable user_Dashboard!: DashboardLayoutDto<DashboardRowDto<DashboardColumnDto>>;
    @observable public_Dashboard!: DashboardLayoutDto<DashboardRowDto<DashboardColumnDto>>;

    @observable areaChart_Configuration!: AreaChart_Configuration<AreaChart_DataModel, AxisModel>;
    @observable waterfallChart_Configuration!: WaterfallChart_Configuration<WaterfallChart_DataModel>;
    @observable violinPlot_Configuration!: ViolinPlot_Configuration<ViolinPlot_DataModel>;
    @observable vennPlot_Configuration!: VennPlot_Configuration<VennPlot_DataModel>;
    @observable sunburstPlot_Configuration!: SunburstPlot_Configuration<SunburstPlot_DataModel>;
    @observable stockPlot_Configuration!: StockPlot_Configuration<StockPlot_DataModel>;
    @observable scatterPlot_Configuration!: ScatterPlot_Configuration<ScatterPlot_DataModel>;
    @observable sankeyPlot_Configuration!: SankeyPlot_Configuration<SankeyPlot_DataModel>;
    @observable roseChart_Configuration!: RoseChart_Configuration<RoseChart_DataModel>;
    @observable ring_Configuration!: Ring_Configuration;
    @observable radialBarChart_Configuration!: RadialBarChart_Configuration<RadialBarChart_DataModel>;
    @observable radarChart_Configuration!: RadarChart_Configuration;
    @observable progress_Configuration!: Progress_Configuration;
    @observable pieChart_Configuration!: PieChart_Configuration<PieChart_DataModel>;
    @observable multiLineChart_Configuration!: MultiLineChart_Configuration<MultiLineChart_DataModel>;
    @observable liquid_Configuration!: Liquid_Configuration;
    @observable lineChart_Configuration!: LineChart_Configuration<LineChart_DataModel>;
    @observable histogram_Configuration!: Histogram_Configuration<Histogram_DataModel>;
    @observable heatMap_Configuration!: HeatMap_Configuration<HeatMap_DataModel>;
    @observable gauge_Configuration!: Gauge_Configuration;
    @observable funnelChart_Configuration!: FunnelChart_Configuration<FunnelChart_DataModel>;
    @observable donutChart_Configuration!: DonutChart_Configuration<DonutChart_DataModel>;
    @observable columnGraph_Configuration!: ColumnGraph_Configuration<ColumnGraph_DataModel>;
    @observable circlePacking_Configuration!: CirclePacking_Configuration<CirclePacking_DataModel<CirclePacking_DataModelChildren>>;
    @observable bullet_Configuration!: Bullet_Configuration<Bullet_DataModel>;
    @observable barChart_Configuration!: BarChart_Configuration<BarChart_DataModel>;
    @observable boxPlot_Configuration!: BoxPlot_Configuration<BoxPlot_DataModel, BoxStyleModel>;

    @action
    async initReportConfiguration(type: number) {

        if (type === DashboardItemTypes.AreaChart) {
            this.areaChart_Configuration =
            {
                data: [],
                xField: '',
                yField: '',
                xAxis: {
                    range: [0, 0],
                    scale: 0
                },
                fill: '',
            };
        }
        else if (type === DashboardItemTypes.BarChart) {
            this.barChart_Configuration = {
                data: [],
                xField: '',
                yField: '',
                seriesField: '',
            };
        }
        else if (type === DashboardItemTypes.BoxPlot) {
            this.boxPlot_Configuration = {
                data: [],
                width: 0,
                height: 0,
                xField: '0',
                yField: ['low', 'q1', 'median', 'q3', 'high'],
                boxStyle: {
                    stroke: '',
                    fill: '',
                    fillOpacity: 0
                }
            };
        }
        else if (type === DashboardItemTypes.BubbleGraph) {
            // Not Available
        }
        else if (type === DashboardItemTypes.Bullet) {
            this.bullet_Configuration = {
                data: [],
                measureField: 'measures',
                rangeField: 'ranges',
                targetField: 'target',
                xField: 'title',
                color: {
                    range: '#f0efff',
                    measure: '#5B8FF9',
                    target: '#3D76DD',
                },
                xAxis: {
                    line: null,
                },
                yAxis: {},
                legend: {}
            };
        }
        else if (type === DashboardItemTypes.Button) {
            // Not Available
        }
        else if (type === DashboardItemTypes.CirclePacking) {
            this.circlePacking_Configuration = {
                autoFit: true,
                data: {
                    name: '',
                    children: []
                },
            };
        }
        else if (type === DashboardItemTypes.ColumnGraph) {
            this.columnGraph_Configuration = {
                data: [],
                xField: '',
                yField: '',
                label: {
                    position: 'middle',
                    style: {
                        fill: '#FFFFFF',
                        opacity: 0.6,
                    },
                },
                xAxis: {
                    label: {
                        autoHide: true,
                        autoRotate: false,
                    },
                },
                meta: {
                    type: {
                        alias: '',
                    },
                    sales: {
                        alias: '',
                    },
                },
            };
        }
        else if (type === DashboardItemTypes.DataGrid) {
            // Not Available
        }
        else if (type === DashboardItemTypes.DonutChart) {
            this.donutChart_Configuration = {
                appendPadding: 10,
                data: [],
                angleField: 'value',
                colorField: 'type',
                radius: 1,
                innerRadius: 0.6,
                label: {
                    type: 'inner',
                    offset: '-50%',
                    content: '{value}',
                    style: {
                        textAlign: 'center',
                        fontSize: '1em'
                    },
                },
                interactions: [
                    {
                        type: 'element-selected',
                    },
                    {
                        type: 'element-active',
                    },
                ],
                statistic: {
                    title: false,
                    content: {
                        style: {
                            whiteSpace: 'pre-wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: '1em'
                        },
                        content: 'Plot',
                    },
                },
            };
        }
        else if (type === DashboardItemTypes.FunnelChart) {
            this.funnelChart_Configuration = {
                data: [],
                xField: 'stage',
                yField: 'number',
            };
        }
        else if (type === DashboardItemTypes.Gauge) {
            this.gauge_Configuration = {

                percent: 0.75,
                range: {
                    color: '#30BF78',
                },
                indicator: {
                    pointer: {
                        style: {
                            stroke: '#D0D0D0',
                        },
                    },
                    pin: {
                        style: {
                            stroke: '#D0D0D0',
                        },
                    },
                },
                axis: {
                    label: {

                    },
                    subTickLine: {
                        count: 3,
                    },
                },
                statistic: {
                    content: {

                    },
                },
            };
        }
        else if (type === DashboardItemTypes.HeatMap) {
            this.heatMap_Configuration = {
                data: [],
                xField: 'name',
                yField: 'country',
                colorField: 'value',
                shape: 'circle',
                sizeRatio: 0.5,
                color: ['#0d5fbb', '#7eadfc', '#0d5fbb', '#7eadfc'],
                label: {
                    style: {
                        fill: '#fff',
                        shadowBlur: 2,
                        shadowColor: 'rgba(0, 0, 0, .45)',
                    },
                },
            };
        }
        else if (type === DashboardItemTypes.Histogram) {
            this.histogram_Configuration = {
                data: [],
                binField: 'value',
                binWidth: 2,
            }
                ;
        }
        else if (type === DashboardItemTypes.LineChart) {
            this.lineChart_Configuration = {
                data: [],
                padding: 'auto',
                xField: 'Date',
                yField: 'scales',
                annotations: [
                    {
                        type: 'regionFilter',
                        start: ['min', 'median'],
                        end: ['max', '0'],
                        color: '#F4664A',
                    },
                    {
                        type: 'text',
                        position: ['min', 'median'],
                        content: '中位数',
                        offsetY: -4,
                        style: {
                            textBaseline: 'bottom',
                        },
                    },
                    {
                        type: 'line',
                        start: ['min', 'median'],
                        end: ['max', 'median'],
                        style: {
                            stroke: '#F4664A',
                            lineDash: [2, 2],
                        },
                    },
                ],
            }
        }
        else if (type === DashboardItemTypes.Liquid) {
            this.liquid_Configuration = {
                percent: 0.25,
                outline: {
                    border: 4,
                    distance: 8,
                },
                wave: {
                    length: 128,
                },
            };
        }
        else if (type === DashboardItemTypes.MultiLineChart) {
            this.multiLineChart_Configuration = {
                data: [],
                xField: 'year',
                yField: 'value',
                seriesField: 'category',
                xAxis: {
                    type: 'time',
                },
                yAxis: {
                    label: {

                    },
                },
            };
        }
        else if (type === DashboardItemTypes.PieChart) {
            this.pieChart_Configuration = {
                appendPadding: 10,
                data: [],
                angleField: 'value',
                colorField: 'type',
                radius: 0.9,
                label: {
                    type: 'inner',
                    offset: '-30%',
                    style: {
                        fontSize: 14,
                        textAlign: 'center',
                    },
                },
                interactions: [{ type: 'element-active' }],
            };
        }
        else if (type === DashboardItemTypes.Progress) {
            this.progress_Configuration = {
                height: 100,
                width: 300,
                autoFit: false,
                percent: 0.536,
                barWidthRatio: 0.3,
                color: ['#F4664A', '#E8EDF3'],
            };
        }
        else if (type === DashboardItemTypes.RadarChart) {
            this.radarChart_Configuration = {
                data: [],
                xField: 'name',
                yField: 'star',
                appendPadding: [0, 10, 0, 10],
                meta: {
                    star: {
                        alias: 'star',
                        min: 0,
                        nice: true,

                    },
                },
                xAxis: {
                    tickLine: null,
                },
                yAxis: {
                    label: false,
                    grid: {
                        alternateColor: 'rgba(0, 0, 0, 0.04)',
                    },
                },
                point: {
                    size: 2,
                },
                area: {},
            };
        }
        else if (type === DashboardItemTypes.RadialBarChart) {
            this.radialBarChart_Configuration = {
                data: [],
                colorField: 'star',
                barStyle: { lineCap: 'round' },
                xField: 'name',
                yField: 'star',
                radius: 0.8,
                innerRadius: 0.2,
                tooltip: {
                },
            };
        }
        else if (type === DashboardItemTypes.Ring) {
            this.ring_Configuration = {
                height: 100,
                width: 100,
                autoFit: false,
                percent: 0.7,
                color: ['#5B8FF9', '#E8EDF3'],
            };
        }
        else if (type === DashboardItemTypes.RoseChart) {
            this.roseChart_Configuration = {
                data: [],
                xField: 'type',
                yField: 'value',
                seriesField: 'type',
                radius: 0.9,
                legend: {
                    position: 'bottom',
                },
            };
        }
        else if (type === DashboardItemTypes.SankeyPlot) {
            this.sankeyPlot_Configuration = {

                data: [],
                sourceField: 'source',
                targetField: 'target',
                weightField: 'value',
                nodeWidthRatio: 0.008,
                nodePaddingRatio: 0.03,
            };
        }
        else if (type === DashboardItemTypes.ScatterPlot) {
            this.scatterPlot_Configuration = {
                appendPadding: 10,
                data: [],
                xField: 'Revenue (Millions)',
                yField: 'Rating',
                shape: 'circle',
                colorField: 'Genre',
                size: 4,
                yAxis: {
                    nice: true,
                    line: {
                        style: {
                            stroke: '#aaa',
                        },
                    },
                },
                xAxis: {
                    min: -100,
                    grid: {
                        line: {
                            style: {
                                stroke: '#eee',
                            },
                        },
                    },
                    line: {
                        style: {
                            stroke: '#aaa',
                        },
                    },
                },
            };
        }
        else if (type === DashboardItemTypes.StockPlot) {
            this.stockPlot_Configuration = {
                data: [],
                xField: 'trade_date',
                yField: ['open', 'close', 'high', 'low'],
            };
        }
        else if (type === DashboardItemTypes.SunburstPlot) {
            this.sunburstPlot_Configuration = {
                data: [],
                innerRadius: 0.3,
                interactions: [
                    {
                        type: 'element-active',
                    },
                ],
                hierarchyConfig: {
                    field: 'sum',
                },
                drilldown: {
                    breadCrumb: {
                        rootText: '起始',
                    },
                },
            }
                ;
        }
        else if (type === DashboardItemTypes.VennPlot) {
             
        }
        else if (type === DashboardItemTypes.ViolinPlot) {
            this.violinPlot_Configuration = {
                height: 500,
                data: [],
                xField: 'x',
                yField: 'y',
            };
        }
        else if (type === DashboardItemTypes.WaterfallChart) {
            this.waterfallChart_Configuration = {
                data: [],
                xField: 'type',
                yField: 'money',
                appendPadding: [15, 0, 0, 0],
                meta: {
                    type: {
                        alias: 'Alias',
                    },
                    money: {

                    },
                },
                label: {
                    style: {
                        fontSize: 10,
                        fill: 'rgba(0,0,0,0.65)',
                    },
                    layout: [
                        {
                            type: 'interval-adjust-position',
                        },
                    ],
                },
                total: {
                    label: 'Label',
                    style: {
                        fill: '#96a6a6',
                    },
                },
            };
        }

        return;
    }

    @action
    async getReport(askDto: DashbordAskDto) {
        if (askDto.type === DashboardItemTypes.AreaChart) {
            let result = await dashboardService.getWidget_AreaChart(askDto);
            this.areaChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.BarChart) {
            let result = await dashboardService.getWidget_BarChart(askDto);
            this.barChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.BoxPlot) {
            let result = await dashboardService.getWidget_BoxPlot(askDto);
            this.boxPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.BubbleGraph) {
            // Not Available
        }
        else if (askDto.type === DashboardItemTypes.Bullet) {
            let result = await dashboardService.getWidget_Bullet(askDto);
            this.bullet_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Button) {
            // Not Available
        }
        else if (askDto.type === DashboardItemTypes.CirclePacking) {
            let result = await dashboardService.getWidget_CirclePacking(askDto);
            this.circlePacking_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.ColumnGraph) {
            let result = await dashboardService.getWidget_ColumnGraph(askDto);
            this.columnGraph_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.DataGrid) {
            // Not Available
        }
        else if (askDto.type === DashboardItemTypes.DonutChart) {
            let result = await dashboardService.getWidget_DonutChart(askDto);
            this.donutChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.FunnelChart) {
            let result = await dashboardService.getWidget_FunnelChart(askDto);
            this.funnelChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Gauge) {
            let result = await dashboardService.getWidget_Gauge(askDto);
            this.gauge_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.HeatMap) {
            let result = await dashboardService.getWidget_HeatMap(askDto);
            this.heatMap_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Histogram) {
            let result = await dashboardService.getWidget_Histogram(askDto);
            this.histogram_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.LineChart) {
            let result = await dashboardService.getWidget_LineChart(askDto);
            this.lineChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Liquid) {
            let result = await dashboardService.getWidget_Liquid(askDto);
            this.liquid_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.MultiLineChart) {
            let result = await dashboardService.getWidget_MultiLineChart(askDto);
            this.multiLineChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.PieChart) {
            let result = await dashboardService.getWidget_PieChart(askDto);
            this.pieChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Progress) {
            let result = await dashboardService.getWidget_Progress(askDto);
            this.progress_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.RadarChart) {
            let result = await dashboardService.getWidget_RadarChart(askDto);
            this.radarChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.RadialBarChart) {
            let result = await dashboardService.getWidget_RadialBarChart(askDto);
            this.radialBarChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Ring) {
            let result = await dashboardService.getWidget_Ring(askDto);
            this.ring_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.RoseChart) {
            let result = await dashboardService.getWidget_RoseChart(askDto);
            this.roseChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.SankeyPlot) {
            let result = await dashboardService.getWidget_SankeyPlot(askDto);
            this.sankeyPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.ScatterPlot) {
            let result = await dashboardService.getWidget_ScatterPlot(askDto);
            this.scatterPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.StockPlot) {
            let result = await dashboardService.getWidget_StockPlot(askDto);
            this.stockPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.SunburstPlot) {
            let result = await dashboardService.getWidget_SunburstPlot(askDto);
            this.sunburstPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.VennPlot) {
            let result = await dashboardService.getWidget_VennPlot(askDto);
            this.vennPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.ViolinPlot) {
            let result = await dashboardService.getWidget_ViolinPlot(askDto);
            this.violinPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.WaterfallChart) {
            let result = await dashboardService.getWidget_WaterfallChart(askDto);
            this.waterfallChart_Configuration = result;
        }
        return;
    }

    //-------------------------------------------------

    @action
    async initUserDashboard() {
        this.user_Dashboard = {
            totalRows: 0,
            rows: []
        };
        return;
    }

    @action
    async initPublicDashboard() {
        this.public_Dashboard = {
            totalRows: 0,
            rows: []
        };
        return;
    }

    //-------------------------------------------------

    @action
    async getUserDashboard() {
        let result = await dashboardService.getUserDashboard();
        this.user_Dashboard = result;
        return;
    }

    @action
    async getPublicDashboard() {
        let result = await dashboardService.getPublicDashboard();
        this.public_Dashboard = result;
        return;
    }
}

export default DashboardStore;
