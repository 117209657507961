
import { MenuSummaryReturnDto } from './dto/summaries/menuSummaryReturnDto'
import { MenuReturnDto } from './dto/menuReturnDto'; 
import { MenuCreateDto } from './dto/menuCreateDto';
import { MenuAskDto } from './dto/menuAskDto';
import http from '../httpService';
import { MenuEntityDto } from './dto/entities/menuEntityDto';
import { MenuItemDto } from './dto/entities/menuItemDto';
import { MenuOptionDto } from './dto/entities/menuOptionDto';
import { MenuRecordDto } from './dto/entities/menuRecordDto';
import { MenuStateDto } from './dto/entities/menuStateDto';
import { MenuTypeDto } from './dto/entities/menuTypeDto'; 
import { EntityCommitContainer, FileUpload } from '../dto/fileActionResultDto';
import { MenuTemplateDto } from './dto/entities/menuTemplateDto';
import { MenuEntitySummaryDto } from './dto/summaries/menuEntitySummaryDto';
import { MenuItemSummaryDto } from './dto/summaries/menuItemSummaryDto';
import { MenuOptionSummaryDto } from './dto/summaries/menuOptionSummaryDto';
import { MenuStateSummaryDto } from './dto/summaries/menuStateSummaryDto';
import { MenuTemplateSummaryDto } from './dto/summaries/menuTemplateSummaryDto';
import { MenuTypeSummaryDto } from './dto/summaries/menuTypeSummaryDto';
import { NoteDto } from '../dto/entities/noteDto';
import { NoteRecordDto } from '../dto/entities/noteRecordDto';
import { NoteRecordSummaryDto } from '../dto/summaries/noteRecordSummaryDto';
import { NoteSummaryDto } from '../dto/summaries/noteSummaryDto';
import { UserListSummaryDto } from '../dto/summaries/userListSummaryDto';
import { UserActionRecordDto } from '../dto/records/userActionRecordDto';
import { ActionSummaryDto } from '../dto/summaries/actionSummaryDto';
import { ValueReturnDto } from '../dto/values/valueReturnDto';
import { TemplateRecordDto } from '../dto/records/templateRecordDto';
import { PersonListSummaryDto } from '../dto/summaries/personListSummaryDto';
import { TeamListSummaryDto } from '../dto/summaries/teamListSummaryDto';
 

class MenuService { 
 
    public async get(askDto: MenuAskDto): Promise<MenuReturnDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getResult = await http.get('api/services/app/Menu/GetEntity', { params: askDto });
        return getResult.data.result;
    }

    public async create(askDto: MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<MenuReturnDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
       
        let createResult = await http.post('api/services/app/Menu/CreateEntity', askDto);
        return createResult.data.result;
    }

    public async update(askDto: MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<MenuReturnDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let updateResult = await http.put('api/services/app/Menu/UpdateEntity', askDto);
        return updateResult.data.result;
    }

    public async delete(askDto: MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<MenuReturnDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let deleteResult = await http.post('api/services/app/Menu/DeleteEntity', askDto);
        return deleteResult.data;
    }

    public async getSummaryView(askDto: MenuAskDto): Promise<MenuSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, MenuEntitySummaryDto, MenuTypeSummaryDto, MenuTemplateSummaryDto, MenuStateSummaryDto, MenuOptionSummaryDto, MenuItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Menu/GetSummary', { params: askDto });
        return getSummaryViewResult.data.result;
    } 
 
    //------------------------------------------------------------------
  
    public async uploadImage(file: FormData): Promise<FileUpload> {
        let getResult = await http.post('api/services/app/Menu/UploadFile', file );
        return getResult.data.result;
    }

    public async getImage(id: number): Promise<FileUpload> {
        let getResult = await http.get('api/services/app/Menu/GetFile?id=' + id);
        return getResult.data.result;
    }
 
    public async getFiles(id: number, type: number): Promise<FileUpload[]> {
        let getFilesResult = await http.get('api/services/app/Menu/GetEntityFiles?id=' + id + '&type=' + type);
        return getFilesResult.data.result;
    }
  
    public async commitEntityFiles(input: EntityCommitContainer): Promise<boolean> {
        let getResult = await http.post('api/services/app/Menu/CommitEntityFiles', input);
        return getResult.data.result;
    }
    
    //------------------------------------------------------------------

    public async getNotes(askDto: MenuAskDto): Promise<MenuSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, MenuEntitySummaryDto, MenuTypeSummaryDto, MenuTemplateSummaryDto, MenuStateSummaryDto, MenuOptionSummaryDto, MenuItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto>> {
        let getRecordsResult = await http.get('api/services/app/Menu/GetNotes', { params: askDto });
        return getRecordsResult.data.result;
    }

    public async addNotes(askDto: MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<MenuReturnDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {

        let getRecordsResult = await http.post('api/services/app/Menu/AddNotes', askDto);
        return getRecordsResult.data.result;
    }

    public async deleteNotes(askDto:  MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<MenuReturnDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getRecordsResult = await http.post('api/services/app/Menu/DeleteNotes', askDto);
        return getRecordsResult.data.result;
    }

    //------------------------------------------------------------------

    public async getActionSummary(askDto: MenuAskDto): Promise<ActionSummaryDto<UserActionRecordDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Records/GetUserActivitySummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }
}

export default new MenuService();
