import { itemTypes } from '../EntityConfigs/itemTypes';
import LoadableComponent from './../Loadable/index';

export const userRouter: any = [
  {
    path: '/user',
    name: 'user',
    title: 'User',
    component: LoadableComponent(() => import('../../components/Layout/UserLayout')),
    isLayout: true,
    showInMenu: true,
  },
  {
    path: '/user/home',
    name: 'home',
    title: 'Joubert Farms',
    component: LoadableComponent(() => import('../../scenes/Login')),
    showInMenu: true,
  }, 
];

export const appRouters: any = [
  {
    id: -1,
    path: '/',
    exact: true,
    name: 'home',
    permission: '',
    title: 'Home',
    icon: 'home',
    component: LoadableComponent(() => import('../../components/Layout/AppLayout')),
    isLayout: true,
    showInMenu: true,
  },
  {
    id: -1,
    path: '/dashboard',
    name: 'dashboard',
    permission: '',
    title: 'Dashboard',
    icon: 'dashboard',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Dashboard')),
  },
  {
    id: itemTypes.Persons,
    path: '/people',
    permission: 'People.ViewTab',
    title: 'People',
    name: 'people',
    icon: 'user',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/People')),
  }, 
  {
    id: itemTypes.Teams,
    path: '/teams',
    permission: 'Teams.ViewTab',
    title: 'Teams',
    name: 'teams',
    icon: 'teams',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Teams')),
  },
  {
    id: itemTypes.Inventory,
    path: '/inventory',
    permission: 'Inventory.ViewTab',
    title: 'Inventory',
    name: 'inventory',
    icon: 'inventory',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Inventory')),
  },
  {
    id: itemTypes.WorkOrders,
    path: '/workorders',
    permission: 'WorkOrder.ViewTab',
    title: 'WorkOrders',
    name: 'workOrder',
    icon: 'workOrder',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/WorkOrders')),
  },
  {
    id: itemTypes.Schedules,
    path: '/scheduling',
    permission: 'Scheduling.ViewTab',
    title: 'Scheduling',
    name: 'scheduling',
    icon: 'scheduling',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Scheduling')),
  },
  {
    id: itemTypes.Communication,
    path: '/communication',
    permission: 'Communication.ViewTab',
    title: 'Communication',
    name: 'communication',
    icon: 'message',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Communication')),
  },  
  {
    id: itemTypes.Reports,
    path: '/reports',
    permission: 'Reports.ViewTab',
    title: 'Reports',
    name: 'reports',
    icon: 'reports',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Reports')),
  },  
  {
    id: itemTypes.Integrations,
    path: '/integrations',
    permission: 'Integrations.ViewTab',
    title: 'Integrations',
    name: 'integrations',
    icon: 'integrations',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Integrations')),
  }, 
  {
    id: itemTypes.Payments,
    path: '/payments',
    permission: 'Payments.ViewTab',
    title: 'Payments',
    name: 'payments',
    icon: 'payments',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Payments')),
  },
  {
    id: itemTypes.Menu,
    path: '/menu',
    permission: 'Configuration.ViewTab',
    title: 'Menus',
    name: 'menus',
    icon: 'layout',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Menu')),
  },
  {
    id: itemTypes.Users,
    path: '/users',
    permission: 'Users.ViewTab',
    title: 'Users',
    name: 'user',
    icon: 'user',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Users')),
  },
  {
    id: itemTypes.Roles,
    path: '/roles',
    permission: 'Roles.ViewTab',
    title: 'Roles',
    name: 'role',
    icon: 'role',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Roles')),
  },
  {
    id: itemTypes.Tenants,
    path: '/tenants',
    permission: 'Tenants.ViewTab',
    title: 'Tenants',
    name: 'tenant',
    icon: 'tenant',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Tenants')),
  }, 
  {
    id: itemTypes.Tenants,
    path: '/infoHub',
    permission: 'InfoHub.ViewTab',
    title: 'Info Hub',
    name: 'infoHub',
    icon: 'infoHub',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/InfoHub')),
  },
  {
    id: -1,
    path: '/logout',
    permission: '',
    title: 'Logout',
    name: 'logout',
    icon: 'sign-out',
    showInMenu: false,
    component: LoadableComponent(() => import('../../components/Logout')),
  },
  {
    id: -1,
    path: '/exception?:type',
    permission: '',
    title: 'exception',
    name: 'exception',
    icon: 'info-circle',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Exception')),
  },
];


export const routers = [...userRouter, ...appRouters];
