export const RelatedEntityTypes = {
    Entity: 0,
    Type: 1,
    Option: 2, 
    Item: 3,
    State: 4,
    Template: 5, 
    Record: 6, 
    Value: 7, 
    TemplateRecord: 8,
    Note: 9,
    NoteRecord: 10,
    User: 11,
    System: 12, 
    Customer: 13,
    Administrator: 14,
    External: 15,
    Custom: 16,
    Fields: 17,
    States: 18,
    People: 19,
    Teams: 20,
    InfoHub: 20,
} 