import { action, observable } from 'mobx';
import { EntityDto } from '../services/dto/entityDto';
import type { PagedResultDto } from '../services/dto/pagedResultDto';
import type { RcFile } from 'antd/lib/upload';
import type { EntityCommitContainer, FileUpload } from '../services/dto/fileActionResultDto';
import type { Data, Fields, Meta, S2DataConfig } from '@antv/s2';
import { RelatedEntityTypes } from '../components/EntityConfigs/relatedEntityTypes';
import type { UploadFile } from 'antd/lib/upload/interface';
import { NoteDto } from '../services/dto/entities/noteDto';
import { NoteRecordDto } from '../services/dto/entities/noteRecordDto';
import type { NoteSummaryDto } from '../services/dto/summaries/noteSummaryDto';
import { NotificationDto } from '../services/dto/entities/notificationDto';
import type { CheckPermissionDto } from '../services/user/dto/checkPermissionDto';
import type { InfoHubReturnDto } from '../services/infoHub/dto/infoHubReturnDto';
import { InfoHubArticleDto } from '../services/infoHub/dto/infoHubArticleDto';
import type { InfoHubCreateDto } from '../services/infoHub/dto/infoHubCreateDto';
import type { InfoHubAskDto } from '../services/infoHub/dto/infoHubAskDto';
import { InfoHubArticleRecordDto } from '../services/infoHub/dto/infoHubArticleRecordDto';
import { DataConsts } from '../components/EntityConfigs/dataConsts';
import { itemTypes } from '../components/EntityConfigs/itemTypes';
import infoHubService from '../services/infoHub/infoHubService';
import { UserActionRecordDto } from '../services/dto/records/userActionRecordDto';
 

class InfoHubStore {  
  //--------------------------------------------------------------------------

  @observable actionSummaryReturn!: PagedResultDto<UserActionRecordDto>;
  
  //Stored as a group, filtered on retrieval, maybe via State within EntityTabView
  @observable cards_CommentData!: Array<any>;
  @observable cards_BodyData!: Array<any>;
  @observable cards_HeaderData!: Array<any>;
  @observable cards_TagData!: Array<any>;

  @observable cards_BodyConfig!: Array<any>;
  @observable cards_HeaderConfig!: Array<any>;
  @observable cards_TagConfig!: Array<any>;
  @observable articleAsk!: InfoHubAskDto;
  @observable articles!: PagedResultDto<InfoHubArticleDto>;
  @observable articleSummary!: PagedResultDto<any>;
  @observable articleList!: PagedResultDto<any>;

  @observable editArticle!: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto>;
  @observable articleCreate!: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto>;
  @observable articleDelete!: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto>;
  @observable articleReturn!: InfoHubReturnDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto>;

  @observable articleSummaryCondensed!: PagedResultDto<any>;

  @observable permissionCheckObject!: CheckPermissionDto;
  @observable profileID: number = 0;
  @observable entityID: number = 0;
  @observable entityType: number = 0;
  @observable currentState: number = 0;
  @observable defaultEntityImages!: Array<UploadFile>;
  @observable defaultEntityFiles!: Array<UploadFile>;
  @observable fileCache!: EntityCommitContainer;

  @observable defaultEntityNotes!: Array<NoteDto>;
  @observable defaultEntityNoteRecords!: Array<NoteRecordDto>;

  @observable currentNotifications: NotificationDto[] = [];

  @observable entityNotesCache!: Array<NoteDto>;
  @observable entityNoteRecordsCache!: Array<NoteRecordDto>;

  @observable entityNotesSummary!: PagedResultDto<NoteSummaryDto>;

  @observable search_Mentions: string[] = [];
  @observable search_Hashtags: string[] = [];

  @observable dataExportTable!: S2DataConfig;
  @observable dataExportTableData: Data[] = [];
  @observable dataExportTableTotalData: Data[] = [];
  @observable dataTableFields: Fields = {
    rows: [
      'id',
      'name',
      'surname',
      'emailAddress',
      'isActive',
      'phoneOne',
      'currentState',
      'addressOne',
      'addressTwo',
      'city',
      'state',
      'zip',
      'phoneTwo',
      'actionRequired',
      'accountType'],

    columns: ['id', 'name', 'title',  ],
    values: ['id', 'name', 'title', ],
  };

  @observable dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "First Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
    
  ];

  @observable infoHubStates: any = {
    totalCount: 3,
    items:
      [
        {
          id: 1,
          name: 'draft',
          title: 'Draft',
          profileImageID: 0,
          displayType: 0,
          forwardText: 'Submit For Review',
          revertText: '',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: true,
          lastActionOnly: false,
          noActions: false,
        },
        {
          id: 2,
          name: 'review',
          title: 'Review',
          profileImageID: 0,
          displayType: 0,
          forwardText: 'Publish',
          revertText: 'Revise',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: false,
          lastActionOnly: false,
          noActions: false,
        },
        {
          id: 3,
          name: 'Public',
          title: 'Public',
          profileImageID: 0,
          displayType: 0,
          forwardText: 'Archive',
          revertText: 'Review',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: false,
          lastActionOnly: false,
          noActions: false,
        },
        {
          id: 4,
          name: 'archived',
          title: 'Archived',
          profileImageID: 0,
          displayType: 0,
          forwardText: '',
          revertText: 'Make Public',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: false,
          lastActionOnly: true,
          noActions: false,
        },
      ]
  }

  @action //gets Notes for initial list
  async getNotes(entityID: number) {

    try {

      var noteAsk = {
        entityType: RelatedEntityTypes.Note,
        entityID: entityID,
        filter: '',
        moduleType: itemTypes.Inventory,
        listFilter: false,
        availableFilter: false,
      }


      let result = await infoHubService.getNotes(noteAsk);
      this.entityNotesSummary.totalCount = result.notes.length;
      //Loop the Results for Note Conversion
      result.notes.map((x: any) => {
        this.entityNotesSummary.items.push(x);


        var noteItem: NoteDto = {
          id: x.id,
          title: x.title,
          type: x.type,
          relatedEntity: x.relatedEntity,
          replyID: x.replyID,
          customColor: x.customColor,
          created: x.created,
          body: x.body,
          isPrivate: x.isPrivate,
          notifyTagged: x.notifyTagged,
          notifyAll: x.notifyAll
        }

        this.defaultEntityNotes.push(noteItem);
        this.entityNotesCache.push(noteItem);

        //x.map((y: NoteRecordSummaryDto) => {
        // var recordItem: NoteRecordDto = {
        //  id: y.id,
        //  noteID: y.noteID,
        //  relatedUser: y.relatedUser,
        //  relatedEntity: y.relatedEntity
        // }

        // this.defaultEntityNoteRecords.push(recordItem);
        // this.entityNoteRecordsCache.push(recordItem);
        // })




      })


    }
    catch { }
    return;
  }

  @action //Add Notes to Temp List
  async addNote(note: NoteSummaryDto) {

    try {

      let noteCache = note;

      var noteItem: NoteDto = {
        id: noteCache.id,
        title: noteCache.title,
        type: noteCache.type,
        relatedEntity: noteCache.relatedEntity,
        replyID: noteCache.replyID,
        customColor: noteCache.customColor,
        created: noteCache.created,
        body: noteCache.body,
        isPrivate: noteCache.isPrivate,
        notifyTagged: noteCache.notifyTagged,
        notifyAll: noteCache.notifyAll
      }

      this.entityNotesCache.push(noteItem);

      noteCache.noteRecords.map((y: NoteRecordDto) => {
        var recordItem: NoteRecordDto = {
          id: y.id,
          noteID: y.noteID,
          relatedUser: y.relatedUser,
          relatedEntity: y.relatedEntity
        }

        this.entityNoteRecordsCache.push(recordItem);

      })

      if (note.id < 0) {
        let url = await this.getProfileImage(note.creatorID);
        if (url === '_') {
          noteCache.profileImageURL = DataConsts.defaultProfileImageURL;
        }
        else {
          noteCache.profileImageURL = url;
        }
      }

      //Add Note to Summary View
      this.entityNotesSummary.items.push(noteCache);
      this.entityNotesSummary.totalCount = this.entityNotesSummary.totalCount + 1;
    }
    catch { }
    return;
  }

  @action //Remove Notes from Temp List
  async removeNote(recordID: number) {

    try {
      this.entityNotesCache = this.entityNotesCache.filter((x: NoteDto) => x.id !== recordID);
      this.entityNoteRecordsCache = this.entityNoteRecordsCache.filter((x: NoteRecordDto) => x.noteID !== recordID);
      this.entityNotesSummary.items = this.entityNotesSummary.items.filter((x: NoteSummaryDto) => x.id !== recordID);
      this.entityNotesSummary.totalCount = this.entityNotesSummary.totalCount - 1;
    }
    catch { }
    return;
  }

  @action //Sync Notes
  async syncNotes(entityID: number) {

    //NOTE: Temp IDs are in the negatives, set 0 to create

    try {

      this.articleCreate.relatedEntityType = RelatedEntityTypes.Note;
      this.articleDelete.relatedEntityType = RelatedEntityTypes.Note;

      //Parse default against temp for Deletes 
      this.defaultEntityNotes.map((x: any) => {

        var containsNote = false;
        this.entityNotesCache.map((y: any) => {
          if (x.id === y.id) { containsNote = true; }
        })

        if (containsNote === false) {
          var updateItem = x;
          updateItem.relatedEntity = entityID;
          this.articleDelete.notes.push(updateItem);
        }

      })

      this.defaultEntityNoteRecords.map((x: any) => {

        var containsNote = false;
        this.entityNoteRecordsCache.map((y: any) => {
          if (x.id === y.id) { containsNote = true; }
        })

        if (containsNote === false) {
          var updateItem = x;
          updateItem.relatedEntity = entityID;
          this.articleDelete.noteRecords.push(updateItem);
        }

      })

      await infoHubService.deleteNotes(this.articleDelete);

      this.entityNotesCache.map((x: any) => {
        if (x.id < 0) {
          var updateItem = x;
          updateItem.id = 0;
          updateItem.relatedEntity = entityID;
          this.articleCreate.notes.push(updateItem);

        }
      });

      this.entityNoteRecordsCache.map((x: any) => {
        if (x.id < 0) {
          var updateItem = x;
          updateItem.relatedEntity = entityID;
          this.articleCreate.noteRecords.push(updateItem);
        }
      });

      await infoHubService.addNotes(this.articleCreate);

    }
    catch { }
    return;
  }
  //--------------------------------------------------------------------------


  @action
  async uploadImage(file: RcFile) {


  }

  @action
  async uploadProfileImage(file: RcFile) {


  }

  @action
  async getProfileImage(recordID: number) {
    if (recordID === 0 || recordID === 1) {
      return '_';
    }
    else {
      let result = await infoHubService.getImage(recordID);
      return result.url;
    }
  }


  @action
  async getFileDownload(recordID: number) {

    if (recordID === 0 || recordID === 1) {
      return '_';
    }
    else {
      let result = await infoHubService.getImage(recordID);

      return result;
    }
  }


  @action //gets files for initial list
  async getFiles() {

    try {

      let result = await infoHubService.getFiles(this.entityID, this.entityType);

      //Add to Default Loads
      result.map((x: FileUpload) => {

        if (x.type === 'image/png') {
          this.defaultEntityImages.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }
        else if (x.type === 'image/jpeg') {
          this.defaultEntityImages.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }
        else if (x.type === 'image/jpg') {
          this.defaultEntityImages.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }
        else {
          this.defaultEntityFiles.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }

        return this.fileCache.fileList.push(x.assetID);
      })
    }
    catch { }
    return;
  }

  @action // 
  async removeFileFromList(file: UploadFile) {
    this.fileCache.fileList = this.fileCache.fileList.filter((x: number) => x.toString() !== file.uid);
    return;
  }

  @action
  async initFileStores() {
    this.defaultEntityImages = [];
    this.defaultEntityFiles = [];
    this.fileCache =
    {
      entityType: RelatedEntityTypes.Entity,
      entityID: 0,
      fileList: []
    };
  }

  @action
  async initUpdateObject() {


    this.entityNotesCache = [];
    this.entityNoteRecordsCache = [];
    this.defaultEntityNotes = [];

    this.defaultEntityNoteRecords = [];
    this.entityNotesSummary = {
      totalCount: 0,
      items: []
    }

    this.articles = {
      totalCount: 0,
      items: []
    }

    this.articleList = {
      totalCount: 0,
      items: []
    }

    this.editArticle = {
      relatedEntityType: 0,
      itemType: 0,
      articles: [],
      articleRecords: [],
      notes: [],
      noteRecords: []
    }

    this.articleCreate = {
      relatedEntityType: 0,
      itemType: 0,
      articles: [],
      articleRecords: [],
      notes: [],
      noteRecords: []
    }

    this.articleDelete = {
      relatedEntityType: 0,
      itemType: 0,
      articles: [],
      articleRecords: [],
      notes: [],
      noteRecords: []
    }

    this.articleReturn = {
      value: '',
      description: '',
      id: 0,
      articles: [],
      articleRecords: [],
      notes: [],
      noteRecords: []
    }



  }

  //--------------------------------------------------------------------------
 
  @action
  async getEntity(askDto: EntityDto) {
    this.entityID = askDto.id;
    this.entityType = RelatedEntityTypes.Entity;
    this.getFiles();

    this.articleReturn = await infoHubService.get({
      entityType: RelatedEntityTypes.Entity,
      entityID: askDto.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
    });



    console.warn('----GET Resonse  Object--');
    console.warn(JSON.stringify(this.articleReturn, null, 4));
  }

  @action
  async createEntity() {

    console.warn('----GO Create Object--');
    console.warn(JSON.stringify(this.articleCreate, null, 4));

    let result = await infoHubService.create(this.articleCreate);
    this.articleReturn = result;

    console.warn('----GET Resonse  Object--');
    console.warn(JSON.stringify(this.articleReturn, null, 4));

    let tempNotes = this.entityNotesCache;
    let tempNoteRecords = this.entityNoteRecordsCache;
    let entitiesList = this.articleReturn.articles;

    entitiesList.map((x: any) => {
      this.entityNotesCache = tempNotes;
      this.entityNoteRecordsCache = tempNoteRecords;
      this.syncNotes(x.id);
    })
  }

  @action
  async updateEntity() {

    let result = await infoHubService.update(this.articleCreate);
    this.articleReturn = result;
 
    this.articleCreate.articleRecords = []; 

    this.fileCache.entityType = this.articleCreate.relatedEntityType;
    this.fileCache.entityID = this.articleReturn.articles[0].id;
    await infoHubService.commitEntityFiles(this.fileCache);
    await this.syncNotes(this.articleReturn.articles[0].id); 
 
  }


  @action
  async deleteEntity(input: EntityDto) {

    this.articleSummary.items = this.articleSummary.items.filter((x: any) => x.id !== input.id);
 

    this.articleDelete.articles.push({
      id: input.id,
      name: '',
      title: '',
      body: '',
      currentState: 0,
      isActive: false, 
      created: '',
      owner: 0,
      creatorID: 0,
      lastModifiedByID: 0,
      currentlyApproved: false
    });

    this.articleDelete.relatedEntityType = RelatedEntityTypes.Entity;
    this.articleDelete.itemType = itemTypes.Inventory;

    await infoHubService.delete(this.articleDelete);
  };

  @action
  async getArticleSummary(keyword: any) {

    this.articleSummary =
    {
      totalCount: 0,
      items: []
    }
 
    this.articleSummaryCondensed =
    {
      totalCount: 0,
      items: []
    }

    this.dataExportTableData = [];
    this.dataExportTableTotalData = [];


    let articleSummary = await infoHubService.getArticleSummaryView({
      entityType: RelatedEntityTypes.Entity,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
    });




    // Loop Retrieved States
    articleSummary.articles.map((x: any) => {
 
      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,
        "currentState": x.currentState === undefined ? 'Unknown' : x.currentState,
        "isActive": x.isActive === undefined ? 'Unknown' : x.isActive,
        "created": x.created === undefined ? 'Unknown' : x.created,
        "owner": x.owner === undefined ? 'Unknown' : x.owner,
        "creatorID": x.creatorID === undefined ? 'creatorID' : x.creatorID,
        "lastModifiedByID": x.lastModifiedByID === undefined ? 'Unknown' : x.lastModifiedByID,
        "lastActionOnly": x.lastActionOnly === undefined ? 'Unknown' : x.title,
        "currentlyApproved": x.currentlyApproved === undefined ? 'Unknown' : x.currentlyApproved,
      }

      if (articleSummary !== undefined) {
        this.articleSummary =
        {
          totalCount: articleSummary.articles.length,
          items: articleSummary.articles
        }
      }

      return this.dataExportTableData.push(dataItem);

    });

    this.dataExportTable = {
      fields: this.dataTableFields,
      "meta": this.dataTableMeta,
      "data": this.dataExportTableData,
      "totalData": this.dataExportTableTotalData,
    }


    if (articleSummary.articles.length === 0) {
      this.articleSummary =
      {
        totalCount: -1,
        items: articleSummary.articles
      }
    }
    else {
      this.articleSummary =
      {
        totalCount: articleSummary.articles.length,
        items: articleSummary.articles
      }
    }
  }

  @action
  async getAllArticles() {

    this.articles = {
      totalCount: 0,
      items: []
    }

    let result = await infoHubService.get({
      entityType: RelatedEntityTypes.Option,
      entityID: 0,
      filter: '',
      listFilter: false,
      availableFilter: false,
    });

    this.articleList =
    {
      totalCount: result.articles.length,
      items: result.articles
    }

  }

  @action
  async getCardData(filter: any) {




    this.cards_CommentData = [{
      email: 'Product was Sold',
      gender: 'string',
      name: {
        first: 'A few moments ago',
        last: 'Noah James',
        title: 'string',
      },
      nat: 'string',
      picture: {
        large: 'https://swilab.com/images/profileExamples/profileExample1.png',
        medium: 'string',
        thumbnail: 'string',
      },
    },
    {
      email: 'Product was Purchased',
      gender: 'string',
      name: {
        first: 'A few moments ago',
        last: 'Abigail Smith',
        title: 'string',
      },
      nat: 'string',
      picture: {
        large: 'https://swilab.com/images/profileExamples/profileExample3.png',
        medium: 'string',
        thumbnail: 'string',
      },

    },];

    this.cards_HeaderData = [{
      year: '',
      value: 30,
      type: 'Not for Sale',
    },
    {
      year: '',
      value: 50,
      type: 'Processing',
    },
    {
      year: '',
      value: 70,
      type: 'Available',
    },];

    this.cards_HeaderConfig = [{
      data: this.cards_HeaderData.reverse(),
      isStack: true,
      xField: 'value',
      yField: 'year',
      seriesField: 'type',
      label: {
        layout: [
          { type: 'interval-adjust-position' },
          { type: 'interval-hide-overlap' },
          { type: 'adjust-color' },
        ],
      },
    }];

    this.cards_BodyData = [
      {
        type: 'Main Warehouse',
        value: 67,
      },
      {
        type: 'Location A',
        value: 45,
      },
      {
        type: 'Location B',
        value: 21,
      },
      {
        type: 'In Transit',
        value: 17,
      },];
    this.cards_BodyConfig = [{
      appendPadding: 0,
      data: this.cards_BodyData,
      innerRadius: 0.71,
      angleField: 'value',
      colorField: 'type',
      radius: 1,

      label: {
        type: 'inner',
        autoRotate: true,
        style: { fontSize: 14, textAlign: 'center' },

      },

    }];

    this.cards_TagData = [
      {
        title: 'AT&T',
        color: 'blue'
      },
      {
        title: 'Verizon',
        color: 'red'
      },
      {
        title: 'Boost',
        color: 'orange'
      },
    ];

    this.cards_TagConfig = [];

 

  }

  @action
  async clearCardData() {

    this.cards_CommentData = [];
    this.cards_BodyData = [];
    this.cards_HeaderData = [];
    this.cards_TagData = [];
    this.cards_BodyConfig = [];
    this.cards_HeaderConfig = [];
    this.cards_TagConfig = [];
  }
  //--------------------------------------------------------------------------


}

export default InfoHubStore;

