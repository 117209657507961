import { L } from "../../lib/abpUtility";

export const itemTypes = {
    Communication: 0,
    Persons: 1,
    Teams: 2,
    Actions: 3,
    Assets: 4,
    Inventory: 5,
    Messages: 6,
    Chats: 7,
    Payments: 8,
    Schedules: 9,
    WorkOrders: 10,
    Records: 11,
    External: 12,
    Users: 13,
    Roles: 14,
    Tenants: 15,
    Integrations: 16,
    Reports: 17,
    Menu: 18,
    InfoHub: 19,

    Communication_text: L('Communication'),
    Persons_text: L('People'),
    Teams_text: L('Teams'),
    Actions_text: L('Actions'),
    Assets_text: L('Assets'),
    Inventory_text: L('Inventory'),
    Messages_text: L('Messages'),
    Chats_text: L('Chats'),
    Payments_text: L('Payments'),
    Schedules_text: L('Scheduling'),
    WorkOrders_text: L('Work Orders'),
    Records_text: L('Records'),
    External_text: L('External'),
    Users_text: L('Users'),
    Roles_text: L('Roles'),
    Tenants_text: L('Tenants'),
    Integrations_text: L('Integrations'),
    Reports_text: L('Reports'),
    Menu_text: L('Menus'),
    InfoHub_text: L('Info Hub'),
};
