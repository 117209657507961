 
import { NoteDto } from "../dto/entities/noteDto";
import { NoteRecordDto } from "../dto/entities/noteRecordDto";
import { FileUpload, EntityCommitContainer } from "../dto/fileActionResultDto"; 
import { UserActionRecordDto } from "../dto/records/userActionRecordDto";
import { ActionSummaryDto } from "../dto/summaries/actionSummaryDto"; 
import http from "../httpService"; 
import { InfoHubArticleDto } from "./dto/infoHubArticleDto";
import { InfoHubArticleRecordDto } from "./dto/infoHubArticleRecordDto";
import { InfoHubAskDto } from "./dto/infoHubAskDto";
import { InfoHubCreateDto } from "./dto/infoHubCreateDto";
import { InfoHubReturnDto } from "./dto/infoHubReturnDto";
import { InfoHubSummaryReturnDto } from "./dto/infoHubSummaryReturnDto";

 

class InfoHubService {

    public async get(askDto: InfoHubAskDto): Promise<InfoHubReturnDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto>> {
        let getResult = await http.get('api/services/app/InfoHub/Get', { params: askDto });
        return getResult.data.result;
    }
 
    public async getAll(askDto: InfoHubAskDto): Promise<InfoHubReturnDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto>> {
        let getResult = await http.get('api/services/app/InfoHub/Get', { params: askDto });
        return getResult.data.result;
    }

    public async create(askDto: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto>) {
        let createResult = await http.post('api/services/app/InfoHub/Create', askDto);
        return createResult.data.result;
    }

    public async update(askDto: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto>) {
        let updateResult = await http.put('api/services/app/InfoHub/Update', askDto);
        return updateResult.data.result;
    }

    public async delete(askDto: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto>) {
        let deleteResult = await http.post('api/services/app/InfoHub/Delete', askDto);
        return deleteResult.data;
    }

    public async getArticleSummaryView(askDto: InfoHubAskDto): Promise<InfoHubSummaryReturnDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto>> {
        let getSummaryViewResult = await http.get('api/services/app/InfoHub/GetSummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }

    //------------------------------------------------------------------

    public async uploadImage(file: FormData): Promise<FileUpload> {
        let getResult = await http.post('api/services/app/InfoHub/UploadFile', file);
        return getResult.data.result;
    }

    public async getImage(id: number): Promise<FileUpload> {
        let getResult = await http.get('api/services/app/InfoHub/GetFile?id=' + id);
        return getResult.data.result;
    }

    public async getFiles(id: number, type: number): Promise<FileUpload[]> {
        let getFilesResult = await http.get('api/services/app/InfoHub/GetEntityFiles?id=' + id + '&type=' + type);
        return getFilesResult.data.result;
    }

    public async commitEntityFiles(input: EntityCommitContainer): Promise<boolean> {
        let getResult = await http.post('api/services/app/InfoHub/CommitEntityFiles', input);
        return getResult.data.result;
    }

    //------------------------------------------------------------------

    public async getNotes(askDto: InfoHubAskDto): Promise<InfoHubSummaryReturnDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto>>{
        let getRecordsResult = await http.get('api/services/app/InfoHub/GetNotes', { params: askDto });
        return getRecordsResult.data.result;
    }

    public async addNotes(askDto: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto>) {

        let getRecordsResult = await http.post('api/services/app/InfoHub/AddNotes', askDto);
        return getRecordsResult.data.result;
    }

    public async deleteNotes(askDto: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto>) {
        let getRecordsResult = await http.post('api/services/app/InfoHub/DeleteNotes', askDto);
        return getRecordsResult.data.result;
    }

    //------------------------------------------------------------------

    public async getActionSummary(askDto: InfoHubAskDto): Promise<ActionSummaryDto<UserActionRecordDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Records/GetUserActivitySummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }

}

export default new InfoHubService();
