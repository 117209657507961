import { ChangeLanguagaInput } from './dto/changeLanguageInput';
import { CreateOrUpdateUserInput } from './dto/createOrUpdateUserInput';
import { EntityDto } from '../../services/dto/entityDto';
import { GetAllUserOutput } from './dto/getAllUserOutput';
import { PagedResultDto } from '../../services/dto/pagedResultDto';
import { PagedUserResultRequestDto } from "./dto/PagedUserResultRequestDto";
import { UpdateUserInput } from './dto/updateUserInput';
import http from '../httpService';
import { EntityCommitContainer, FileUpload } from '../dto/fileActionResultDto';
import { UserAskDto } from './dto/userAskDto';
import { ItemDto } from '../dto/entities/itemDto';
import { NoteDto } from '../dto/entities/noteDto';
import { NoteRecordDto } from '../dto/entities/noteRecordDto';
import { OptionDto } from '../dto/entities/optionDto';
import { RecordDto } from '../dto/entities/recordDto';
import { StateDto } from '../dto/entities/stateDto';
import { TemplateDto } from '../dto/entities/templateDto';
import { TypeDto } from '../dto/entities/typeDto';
import { ReturnDto } from '../dto/returnDto';
import { CreateDto } from '../dto/createDto';
import { SummaryReturnDto } from '../dto/summaryReturnDto';
import { EntitySummaryDto } from '../dto/summaries/entitySummaryDto';
import { ItemSummaryDto } from '../dto/summaries/itemSummaryDto';
import { NoteRecordSummaryDto } from '../dto/summaries/noteRecordSummaryDto';
import { NoteSummaryDto } from '../dto/summaries/noteSummaryDto';
import { OptionSummaryDto } from '../dto/summaries/optionSummaryDto';
import { StateSummaryDto } from '../dto/summaries/stateSummaryDto';
import { TemplateSummaryDto } from '../dto/summaries/templateSummaryDto';
import { TypeSummaryDto } from '../dto/summaries/typeSummaryDto';
import { UserListSummaryDto } from '../dto/summaries/userListSummaryDto';
import { UserActionRecordDto } from '../dto/records/userActionRecordDto';
import { ActionSummaryDto } from '../dto/summaries/actionSummaryDto';
import { UserEntitySummaryDto } from '../dto/summaries/userEntitySummaryDto';
import { TemplateRecordDto } from '../dto/records/templateRecordDto';

class UserService {
  public async create(createUserInput: CreateOrUpdateUserInput) {
    let result = await http.post('api/services/app/User/Create', createUserInput);
    return result.data.result;
  }

  public async update(updateUserInput: UpdateUserInput) {
    let result = await http.put('api/services/app/User/Update', updateUserInput);
    return result.data.result;
  }

  public async delete(entityDto: EntityDto) {
    let result = await http.delete('api/services/app/User/Delete', { params: entityDto });
    return result.data;
  }

  public async getRoles() {
    let result = await http.get('api/services/app/User/GetRoles');
    return result.data.result.items;
  }

  public async changeLanguage(changeLanguageInput: ChangeLanguagaInput) {
    let result = await http.post('api/services/app/User/ChangeLanguage', changeLanguageInput);
    return result.data;
  }

  public async get(entityDto: EntityDto): Promise<CreateOrUpdateUserInput> {
    let result = await http.get('api/services/app/User/Get', { params: entityDto });
    return result.data.result;
  }

  public async getAll(pagedFilterAndSortedRequest: PagedUserResultRequestDto): Promise<PagedResultDto<GetAllUserOutput>> {
    let result = await http.get('api/services/app/User/GetAll', { params: pagedFilterAndSortedRequest });
    return result.data.result;
  }

  //------------------------------------------------------------------

  public async uploadImage(file: FormData): Promise<FileUpload> {
    let getResult = await http.post('api/services/app/User/UploadFile', file);
    return getResult.data.result;
  }

  public async getImage(id: number): Promise<FileUpload> {
    let getResult = await http.get('api/services/app/User/GetFile?id=' + id);

    return getResult.data.result;
  }

  public async getFiles(id: number, type: number): Promise<FileUpload[]> {
    let getFilesResult = await http.get('api/services/app/User/GetEntityFiles?id=' + id + '&type=' + type);
    return getFilesResult.data.result;
  }

  public async commitEntityFiles(input: EntityCommitContainer): Promise<boolean> {
    let getResult = await http.post('api/services/app/User/CommitEntityFiles', input);
    return getResult.data.result;
  }

  //------------------------------------------------------------------

  public async getNotes(askDto: UserAskDto): Promise<SummaryReturnDto<UserListSummaryDto, EntitySummaryDto, TypeSummaryDto, TemplateSummaryDto, StateSummaryDto, OptionSummaryDto, ItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto>> {
    let getRecordsResult = await http.get('api/services/app/User/GetNotes', { params: askDto });
    return getRecordsResult.data.result;
  }

  public async addNotes(askDto: CreateDto<EntityDto, ItemDto, OptionDto, RecordDto, StateDto, TypeDto, TemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<ReturnDto<EntityDto, ItemDto, OptionDto, RecordDto, StateDto, TypeDto, TemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>> {

    let getRecordsResult = await http.post('api/services/app/User/AddNotes', askDto);
    return getRecordsResult.data.result;
  }

  public async deleteNotes(askDto: CreateDto<EntityDto, ItemDto, OptionDto, RecordDto, StateDto, TypeDto, TemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<ReturnDto<EntityDto, ItemDto, OptionDto, RecordDto, StateDto, TypeDto, TemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>> {
    let getRecordsResult = await http.post('api/services/app/User/DeleteNotes', askDto);
    return getRecordsResult.data.result;
  }

  //------------------------------------------------------------------

  public async getActionSummary(askDto: UserAskDto): Promise<ActionSummaryDto<UserActionRecordDto>> {
    let getSummaryViewResult = await http.get('api/services/app/Records/GetUserActivitySummary', { params: askDto });
    return getSummaryViewResult.data.result;
  }

  public async getUserActivitySummary(askDto: UserAskDto): Promise<UserEntitySummaryDto<EntitySummaryDto>> {
    let getSummaryViewResult = await http.get('api/services/app/Records/GetUserActivitySummary', { params: askDto });
    return getSummaryViewResult.data.result;
  }

}

export default new UserService();
