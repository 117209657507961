
import { CommunicationSummaryReturnDto } from './dto/summaries/communicationSummaryReturnDto'
import { CommunicationReturnDto } from './dto/communicationReturnDto'; 
import { CommunicationCreateDto } from './dto/communicationCreateDto';
import { CommunicationAskDto } from './dto/communicationAskDto';
import http from '../httpService';
import { CommunicationEntityDto } from './dto/entities/communicationEntityDto';
import { CommunicationItemDto } from './dto/entities/communicationItemDto';
import { CommunicationOptionDto } from './dto/entities/communicationOptionDto';
import { CommunicationRecordDto } from './dto/entities/communicationRecordDto';
import { CommunicationStateDto } from './dto/entities/communicationStateDto';
import { CommunicationTypeDto } from './dto/entities/communicationTypeDto'; 
import { EntityCommitContainer, FileUpload } from '../dto/fileActionResultDto';
import { CommunicationTemplateDto } from './dto/entities/communicationTemplateDto';
import { CommunicationEntitySummaryDto } from './dto/summaries/communicationEntitySummaryDto';
import { CommunicationItemSummaryDto } from './dto/summaries/communicationItemSummaryDto';
import { CommunicationOptionSummaryDto } from './dto/summaries/communicationOptionSummaryDto';
import { CommunicationStateSummaryDto } from './dto/summaries/communicationStateSummaryDto';
import { CommunicationTemplateSummaryDto } from './dto/summaries/communicationTemplateSummaryDto';
import { CommunicationTypeSummaryDto } from './dto/summaries/communicationTypeSummaryDto';
import { NoteDto } from '../dto/entities/noteDto';
import { NoteRecordDto } from '../dto/entities/noteRecordDto';
import { NoteSummaryDto } from '../dto/summaries/noteSummaryDto';
import { NoteRecordSummaryDto } from '../dto/summaries/noteRecordSummaryDto';
import { UserListSummaryDto } from '../dto/summaries/userListSummaryDto';
import { UserActionRecordDto } from '../dto/records/userActionRecordDto';
import { ActionSummaryDto } from '../dto/summaries/actionSummaryDto';
import { ValueReturnDto } from '../dto/values/valueReturnDto';
import { TemplateRecordDto } from '../dto/records/templateRecordDto';
import { PersonListSummaryDto } from '../dto/summaries/personListSummaryDto';
import { TeamListSummaryDto } from '../dto/summaries/teamListSummaryDto';
  

class CommunicationService { 
 
    public async get(askDto: CommunicationAskDto): Promise<CommunicationReturnDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getResult = await http.get('api/services/app/Communication/GetEntity', { params: askDto });
        return getResult.data.result;
    }

    public async create(askDto: CommunicationCreateDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<CommunicationReturnDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let createResult = await http.post('api/services/app/Communication/CreateEntity', askDto);
        return createResult.data.result;
    }

    public async update(askDto: CommunicationCreateDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<CommunicationReturnDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let updateResult = await http.put('api/services/app/Communication/UpdateEntity', askDto);
        return updateResult.data.result;
    }

    public async delete(askDto: CommunicationCreateDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<CommunicationReturnDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let deleteResult = await http.post('api/services/app/Communication/DeleteEntity', askDto);
        return deleteResult.data;
    }

    public async getSummaryView(askDto: CommunicationAskDto): Promise<CommunicationSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, CommunicationEntitySummaryDto, CommunicationTypeSummaryDto, CommunicationTemplateSummaryDto, CommunicationStateSummaryDto, CommunicationOptionSummaryDto, CommunicationItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Communication/GetSummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }
 
    //------------------------------------------------------------------
  
    public async uploadImage(file: FormData): Promise<FileUpload> {
        let getResult = await http.post('api/services/app/Communication/UploadFile', file );
        return getResult.data.result;
    }

    public async getImage(id: number): Promise<FileUpload> {
        let getResult = await http.get('api/services/app/Communication/GetFile?id=' + id);
        return getResult.data.result;
    }
 
    public async getFiles(id: number, type: number): Promise<FileUpload[]> {
        let getFilesResult = await http.get('api/services/app/Communication/GetEntityFiles?id=' + id + '&type=' + type);
        return getFilesResult.data.result;
    }
  
    public async commitEntityFiles(input: EntityCommitContainer): Promise<boolean> {
        let getResult = await http.post('api/services/app/Communication/CommitEntityFiles', input);
        return getResult.data.result;
    }

    //------------------------------------------------------------------

    public async getNotes(askDto: CommunicationAskDto): Promise<CommunicationSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, CommunicationEntitySummaryDto, CommunicationTypeSummaryDto, CommunicationTemplateSummaryDto, CommunicationStateSummaryDto, CommunicationOptionSummaryDto, CommunicationItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto>> {
        let getRecordsResult = await http.get('api/services/app/Communication/GetNotes', { params: askDto });
        return getRecordsResult.data.result;
    }

    public async addNotes(askDto: CommunicationCreateDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<CommunicationReturnDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {

        let getRecordsResult = await http.post('api/services/app/Communication/AddNotes', askDto);
        return getRecordsResult.data.result;
    }

    public async deleteNotes(askDto:  CommunicationCreateDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<CommunicationReturnDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getRecordsResult = await http.post('api/services/app/Communication/DeleteNotes', askDto);
        return getRecordsResult.data.result;
    }

    //------------------------------------------------------------------

    public async getActionSummary(askDto: CommunicationAskDto): Promise<ActionSummaryDto<UserActionRecordDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Records/GetUserActivitySummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }
}

export default new CommunicationService();
