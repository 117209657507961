
import { PeopleSummaryReturnDto } from './dto/summaries/peopleSummaryReturnDto'
import { PeopleReturnDto } from './dto/peopleReturnDto'; 
import { PeopleCreateDto } from './dto/peopleCreateDto';
import { PeopleAskDto } from './dto/peopleAskDto';
import http from '../httpService';
import { PeopleEntityDto } from './dto/entities/peopleEntityDto';
import { PeopleItemDto } from './dto/entities/peopleItemDto';
import { PeopleOptionDto } from './dto/entities/peopleOptionDto';
import { PeopleRecordDto } from './dto/entities/peopleRecordDto';
import { PeopleStateDto } from './dto/entities/peopleStateDto';
import { PeopleTypeDto } from './dto/entities/peopleTypeDto'; 
import { EntityCommitContainer, FileUpload } from '../dto/fileActionResultDto';
import { PeopleTemplateDto } from './dto/entities/peopleTemplateDto';
import { PeopleEntitySummaryDto } from './dto/summaries/peopleEntitySummaryDto';
import { PeopleItemSummaryDto } from './dto/summaries/peopleItemSummaryDto';
import { PeopleOptionSummaryDto } from './dto/summaries/peopleOptionSummaryDto';
import { PeopleStateSummaryDto } from './dto/summaries/peopleStateSummaryDto';
import { PeopleTemplateSummaryDto } from './dto/summaries/peopleTemplateSummaryDto';
import { PeopleTypeSummaryDto } from './dto/summaries/peopleTypeSummaryDto';
import { NoteDto } from '../dto/entities/noteDto';
import { NoteRecordDto } from '../dto/entities/noteRecordDto';
import { NoteRecordSummaryDto } from '../dto/summaries/noteRecordSummaryDto';
import { NoteSummaryDto } from '../dto/summaries/noteSummaryDto';
import { UserListSummaryDto } from '../dto/summaries/userListSummaryDto';
import { UserActionRecordDto } from '../dto/records/userActionRecordDto';
import { ActionSummaryDto } from '../dto/summaries/actionSummaryDto';
import { ValueReturnDto } from '../dto/values/valueReturnDto';
import { TemplateRecordDto } from '../dto/records/templateRecordDto';
import { PersonListSummaryDto } from '../dto/summaries/personListSummaryDto';
import { TeamListSummaryDto } from '../dto/summaries/teamListSummaryDto';
 

class PeopleService { 
 
    public async get(askDto: PeopleAskDto): Promise<PeopleReturnDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getResult = await http.get('api/services/app/Person/GetEntity', { params: askDto });
        return getResult.data.result;
    }

    public async create(askDto: PeopleCreateDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<PeopleReturnDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let createResult = await http.post('api/services/app/Person/CreateEntity', askDto);
        return createResult.data.result;
    }

    public async update(askDto: PeopleCreateDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<PeopleReturnDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let updateResult = await http.put('api/services/app/Person/UpdateEntity', askDto);
        return updateResult.data.result;
    }

    public async delete(askDto: PeopleCreateDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<PeopleReturnDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let deleteResult = await http.post('api/services/app/Person/DeleteEntity', askDto);
        return deleteResult.data;
    }

    public async getSummaryView(askDto: PeopleAskDto): Promise<PeopleSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, PeopleEntitySummaryDto, PeopleTypeSummaryDto, PeopleTemplateSummaryDto, PeopleStateSummaryDto, PeopleOptionSummaryDto, PeopleItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Person/GetSummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }
   
    //------------------------------------------------------------------
  
    public async uploadImage(file: FormData): Promise<FileUpload> {
        let getResult = await http.post('api/services/app/Person/UploadFile', file );
        return getResult.data.result;
    }

    public async getImage(id: number): Promise<FileUpload> {
        let getResult = await http.get('api/services/app/Person/GetFile?id=' + id);
        return getResult.data.result;
    }
 
    public async getFiles(id: number, type: number): Promise<FileUpload[]> {
        let getFilesResult = await http.get('api/services/app/Person/GetEntityFiles?id=' + id + '&type=' + type);
        return getFilesResult.data.result;
    }
  
    public async commitEntityFiles(input: EntityCommitContainer): Promise<boolean> {
        let getResult = await http.post('api/services/app/Person/CommitEntityFiles', input);
        return getResult.data.result;
    }
    
    //------------------------------------------------------------------

    public async getNotes(askDto: PeopleAskDto): Promise<PeopleSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, PeopleEntitySummaryDto, PeopleTypeSummaryDto, PeopleTemplateSummaryDto, PeopleStateSummaryDto, PeopleOptionSummaryDto, PeopleItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto>> {
        let getRecordsResult = await http.get('api/services/app/Person/GetNotes', { params: askDto });
        return getRecordsResult.data.result;
    }

    public async addNotes(askDto: PeopleCreateDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<PeopleReturnDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {

        let getRecordsResult = await http.post('api/services/app/Person/AddNotes', askDto);
        return getRecordsResult.data.result;
    }

    public async deleteNotes(askDto:  PeopleCreateDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<PeopleReturnDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getRecordsResult = await http.post('api/services/app/Person/DeleteNotes', askDto);
        return getRecordsResult.data.result;
    }

    //------------------------------------------------------------------

    public async getActionSummary(askDto: PeopleAskDto): Promise<ActionSummaryDto<UserActionRecordDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Records/GetUserActivitySummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }
}

export default new PeopleService();
