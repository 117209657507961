
import { PaymentSummaryReturnDto } from './dto/summaries/paymentSummaryReturnDto'
import { PaymentReturnDto } from './dto/paymentReturnDto'; 
import { PaymentCreateDto } from './dto/paymentCreateDto';
import { PaymentAskDto } from './dto/paymentAskDto';
import http from '../httpService';
import { PaymentEntityDto } from './dto/entities/paymentEntityDto';
import { PaymentItemDto } from './dto/entities/paymentItemDto';
import { PaymentOptionDto } from './dto/entities/paymentOptionDto';
import { PaymentRecordDto } from './dto/entities/paymentRecordDto';
import { PaymentStateDto } from './dto/entities/paymentStateDto';
import { PaymentTypeDto } from './dto/entities/paymentTypeDto'; 
import { EntityCommitContainer, FileUpload } from '../dto/fileActionResultDto';
import { PaymentTemplateDto } from './dto/entities/paymentTemplateDto';
import { PaymentEntitySummaryDto } from './dto/summaries/paymentEntitySummaryDto';
import { PaymentItemSummaryDto } from './dto/summaries/paymentItemSummaryDto';
import { PaymentOptionSummaryDto } from './dto/summaries/paymentOptionSummaryDto';
import { PaymentStateSummaryDto } from './dto/summaries/paymentStateSummaryDto';
import { PaymentTemplateSummaryDto } from './dto/summaries/paymentTemplateSummaryDto';
import { PaymentTypeSummaryDto } from './dto/summaries/paymentTypeSummaryDto';
import { NoteDto } from '../dto/entities/noteDto';
import { NoteRecordDto } from '../dto/entities/noteRecordDto';
import { NoteRecordSummaryDto } from '../dto/summaries/noteRecordSummaryDto';
import { NoteSummaryDto } from '../dto/summaries/noteSummaryDto';
import { UserListSummaryDto } from '../dto/summaries/userListSummaryDto';
import { UserActionRecordDto } from '../dto/records/userActionRecordDto';
import { ActionSummaryDto } from '../dto/summaries/actionSummaryDto';
import { ValueReturnDto } from '../dto/values/valueReturnDto';
import { TemplateRecordDto } from '../dto/records/templateRecordDto';
import { PersonListSummaryDto } from '../dto/summaries/personListSummaryDto';
import { TeamListSummaryDto } from '../dto/summaries/teamListSummaryDto';
 

class PaymentService { 
 
    public async get(askDto: PaymentAskDto): Promise<PaymentReturnDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getResult = await http.get('api/services/app/Payments/GetEntity', { params: askDto });
        return getResult.data.result;
    }

    public async create(askDto: PaymentCreateDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<PaymentReturnDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let createResult = await http.post('api/services/app/Payments/CreateEntity', askDto);
        return createResult.data.result;
    }

    public async update(askDto: PaymentCreateDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<PaymentReturnDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let updateResult = await http.put('api/services/app/Payments/UpdateEntity', askDto);
        return updateResult.data.result;
    }

    public async delete(askDto: PaymentCreateDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<PaymentReturnDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let deleteResult = await http.post('api/services/app/Payments/DeleteEntity', askDto);
        return deleteResult.data;
    }

    public async getSummaryView(askDto: PaymentAskDto): Promise<PaymentSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, PaymentEntitySummaryDto, PaymentTypeSummaryDto, PaymentTemplateSummaryDto, PaymentStateSummaryDto, PaymentOptionSummaryDto, PaymentItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Payments/GetSummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }
   
    //------------------------------------------------------------------
  
    public async uploadImage(file: FormData): Promise<FileUpload> {
        let getResult = await http.post('api/services/app/Payments/UploadFile', file );
        return getResult.data.result;
    }

    public async getImage(id: number): Promise<FileUpload> {
        let getResult = await http.get('api/services/app/Payments/GetFile?id=' + id);
        return getResult.data.result;
    }
 
    public async getFiles(id: number, type: number): Promise<FileUpload[]> {
        let getFilesResult = await http.get('api/services/app/Payments/GetEntityFiles?id=' + id + '&type=' + type);
        return getFilesResult.data.result;
    }
  
    public async commitEntityFiles(input: EntityCommitContainer): Promise<boolean> {
        let getResult = await http.post('api/services/app/Payments/CommitEntityFiles', input);
        return getResult.data.result;
    }
    
    //------------------------------------------------------------------

    public async getNotes(askDto: PaymentAskDto): Promise<PaymentSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, PaymentEntitySummaryDto, PaymentTypeSummaryDto, PaymentTemplateSummaryDto, PaymentStateSummaryDto, PaymentOptionSummaryDto, PaymentItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto>> {
        let getRecordsResult = await http.get('api/services/app/Payments/GetNotes', { params: askDto });
        return getRecordsResult.data.result;
    }

    public async addNotes(askDto: PaymentCreateDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<PaymentReturnDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {

        let getRecordsResult = await http.post('api/services/app/Payments/AddNotes', askDto);
        return getRecordsResult.data.result;
    }

    public async deleteNotes(askDto:  PaymentCreateDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<PaymentReturnDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getRecordsResult = await http.post('api/services/app/Payments/DeleteNotes', askDto);
        return getRecordsResult.data.result;
    }

    //------------------------------------------------------------------

    public async getActionSummary(askDto: PaymentAskDto): Promise<ActionSummaryDto<UserActionRecordDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Records/GetUserActivitySummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }
}

export default new PaymentService();
