
import { SchedulingSummaryReturnDto } from './dto/summaries/schedulingSummaryReturnDto'
import { SchedulingReturnDto } from './dto/schedulingReturnDto'; 
import { SchedulingCreateDto } from './dto/schedulingCreateDto';
import { SchedulingAskDto } from './dto/schedulingAskDto';
import http from '../httpService';
import { SchedulingEntityDto } from './dto/entities/schedulingEntityDto';
import { SchedulingItemDto } from './dto/entities/schedulingItemDto';
import { SchedulingOptionDto } from './dto/entities/schedulingOptionDto';
import { SchedulingRecordDto } from './dto/entities/schedulingRecordDto';
import { SchedulingStateDto } from './dto/entities/schedulingStateDto';
import { SchedulingTypeDto } from './dto/entities/schedulingTypeDto'; 
import { EntityCommitContainer, FileUpload } from '../dto/fileActionResultDto';
import { SchedulingTemplateDto } from './dto/entities/schedulingTemplateDto';
import { SchedulingEntitySummaryDto } from './dto/summaries/schedulingEntitySummaryDto';
import { SchedulingItemSummaryDto } from './dto/summaries/schedulingItemSummaryDto';
import { SchedulingOptionSummaryDto } from './dto/summaries/schedulingOptionSummaryDto';
import { SchedulingStateSummaryDto } from './dto/summaries/schedulingStateSummaryDto';
import { SchedulingTemplateSummaryDto } from './dto/summaries/schedulingTemplateSummaryDto';
import { SchedulingTypeSummaryDto } from './dto/summaries/schedulingTypeSummaryDto';
import { NoteDto } from '../dto/entities/noteDto';
import { NoteRecordDto } from '../dto/entities/noteRecordDto';
import { NoteRecordSummaryDto } from '../dto/summaries/noteRecordSummaryDto';
import { NoteSummaryDto } from '../dto/summaries/noteSummaryDto';
import { UserListSummaryDto } from '../dto/summaries/userListSummaryDto';
import { UserActionRecordDto } from '../dto/records/userActionRecordDto';
import { ActionSummaryDto } from '../dto/summaries/actionSummaryDto';
import { ValueReturnDto } from '../dto/values/valueReturnDto';
import { TemplateRecordDto } from '../dto/records/templateRecordDto';
import { PersonListSummaryDto } from '../dto/summaries/personListSummaryDto';
import { TeamListSummaryDto } from '../dto/summaries/teamListSummaryDto';
 

class SchedulingService { 
 
    public async get(askDto: SchedulingAskDto): Promise<SchedulingReturnDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getResult = await http.get('api/services/app/Scheduling/GetEntity', { params: askDto });
        return getResult.data.result;
    }

    public async create(askDto: SchedulingCreateDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<SchedulingReturnDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let createResult = await http.post('api/services/app/Scheduling/CreateEntity', askDto);
        return createResult.data.result;
    }

    public async update(askDto: SchedulingCreateDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<SchedulingReturnDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let updateResult = await http.put('api/services/app/Scheduling/UpdateEntity', askDto);
        return updateResult.data.result;
    }

    public async delete(askDto: SchedulingCreateDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<SchedulingReturnDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let deleteResult = await http.post('api/services/app/Scheduling/DeleteEntity', askDto);
        return deleteResult.data;
    }

    public async getSummaryView(askDto: SchedulingAskDto): Promise<SchedulingSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, SchedulingEntitySummaryDto, SchedulingTypeSummaryDto, SchedulingTemplateSummaryDto, SchedulingStateSummaryDto, SchedulingOptionSummaryDto, SchedulingItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Scheduling/GetSummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }
   
    //------------------------------------------------------------------
  
    public async uploadImage(file: FormData): Promise<FileUpload> {
        let getResult = await http.post('api/services/app/Scheduling/UploadFile', file );
        return getResult.data.result;
    }

    public async getImage(id: number): Promise<FileUpload> {
        let getResult = await http.get('api/services/app/Scheduling/GetFile?id=' + id);
        return getResult.data.result;
    }
 
    public async getFiles(id: number, type: number): Promise<FileUpload[]> {
        let getFilesResult = await http.get('api/services/app/Scheduling/GetEntityFiles?id=' + id + '&type=' + type);
        return getFilesResult.data.result;
    }
  
    public async commitEntityFiles(input: EntityCommitContainer): Promise<boolean> {
        let getResult = await http.post('api/services/app/Scheduling/CommitEntityFiles', input);
        return getResult.data.result;
    }
    
    //------------------------------------------------------------------

    public async getNotes(askDto: SchedulingAskDto): Promise<SchedulingSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, SchedulingEntitySummaryDto, SchedulingTypeSummaryDto, SchedulingTemplateSummaryDto, SchedulingStateSummaryDto, SchedulingOptionSummaryDto, SchedulingItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto>> {
        let getRecordsResult = await http.get('api/services/app/Scheduling/GetNotes', { params: askDto });
        return getRecordsResult.data.result;
    }

    public async addNotes(askDto: SchedulingCreateDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<SchedulingReturnDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {

        let getRecordsResult = await http.post('api/services/app/Scheduling/AddNotes', askDto);
        return getRecordsResult.data.result;
    }

    public async deleteNotes(askDto:  SchedulingCreateDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<SchedulingReturnDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getRecordsResult = await http.post('api/services/app/Scheduling/DeleteNotes', askDto);
        return getRecordsResult.data.result;
    }

    //------------------------------------------------------------------

    public async getActionSummary(askDto: SchedulingAskDto): Promise<ActionSummaryDto<UserActionRecordDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Records/GetUserActivitySummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }
}

export default new SchedulingService();
