
import { WorkOrderSummaryReturnDto } from './dto/summaries/workOrderSummaryReturnDto'
import { WorkOrderReturnDto } from './dto/workOrderReturnDto'; 
import { WorkOrderCreateDto } from './dto/workOrderCreateDto';
import { WorkOrderAskDto } from './dto/workOrderAskDto';
import http from '../httpService';
import { WorkOrderEntityDto } from './dto/entities/workOrderEntityDto';
import { WorkOrderItemDto } from './dto/entities/workOrderItemDto';
import { WorkOrderOptionDto } from './dto/entities/workOrderOptionDto';
import { WorkOrderRecordDto } from './dto/entities/workOrderRecordDto';
import { WorkOrderStateDto } from './dto/entities/workOrderStateDto';
import { WorkOrderTypeDto } from './dto/entities/workOrderTypeDto'; 
import { EntityCommitContainer, FileUpload } from '../dto/fileActionResultDto';
import { WorkOrderTemplateDto } from './dto/entities/workOrderTemplateDto';
import { WorkOrderEntitySummaryDto } from './dto/summaries/workOrderEntitySummaryDto';
import { WorkOrderItemSummaryDto } from './dto/summaries/workOrderItemSummaryDto';
import { WorkOrderOptionSummaryDto } from './dto/summaries/workOrderOptionSummaryDto';
import { WorkOrderStateSummaryDto } from './dto/summaries/workOrderStateSummaryDto';
import { WorkOrderTemplateSummaryDto } from './dto/summaries/workOrderTemplateSummaryDto';
import { WorkOrderTypeSummaryDto } from './dto/summaries/workOrderTypeSummaryDto';
import { NoteDto } from '../dto/entities/noteDto';
import { NoteRecordDto } from '../dto/entities/noteRecordDto';
import { NoteRecordSummaryDto } from '../dto/summaries/noteRecordSummaryDto';
import { NoteSummaryDto } from '../dto/summaries/noteSummaryDto';
import { UserListSummaryDto } from '../dto/summaries/userListSummaryDto';
import { UserActionRecordDto } from '../dto/records/userActionRecordDto';
import { ActionSummaryDto } from '../dto/summaries/actionSummaryDto';
import { ValueReturnDto } from '../dto/values/valueReturnDto';
import { TemplateRecordDto } from '../dto/records/templateRecordDto';
import { PersonListSummaryDto } from '../dto/summaries/personListSummaryDto';
import { TeamListSummaryDto } from '../dto/summaries/teamListSummaryDto';
 

class WorkOrderService { 
 
    public async get(askDto: WorkOrderAskDto): Promise<WorkOrderReturnDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getResult = await http.get('api/services/app/WorkOrder/GetEntity', { params: askDto });
        return getResult.data.result;
    }

    public async create(askDto: WorkOrderCreateDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<WorkOrderReturnDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let createResult = await http.post('api/services/app/WorkOrder/CreateEntity', askDto);
        return createResult.data.result;
    }

    public async update(askDto: WorkOrderCreateDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<WorkOrderReturnDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let updateResult = await http.put('api/services/app/WorkOrder/UpdateEntity', askDto);
        return updateResult.data.result;
    }

    public async delete(askDto: WorkOrderCreateDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<WorkOrderReturnDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let deleteResult = await http.post('api/services/app/WorkOrder/DeleteEntity', askDto);
        return deleteResult.data;
    }

    public async getSummaryView(askDto: WorkOrderAskDto): Promise<WorkOrderSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, WorkOrderEntitySummaryDto, WorkOrderTypeSummaryDto, WorkOrderTemplateSummaryDto, WorkOrderStateSummaryDto, WorkOrderOptionSummaryDto, WorkOrderItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto>> {
        let getSummaryViewResult = await http.get('api/services/app/WorkOrder/GetSummary', { params: askDto });
        return getSummaryViewResult.data.result;
    } 

    //------------------------------------------------------------------
  
    public async uploadImage(file: FormData): Promise<FileUpload> {
        let getResult = await http.post('api/services/app/WorkOrder/UploadFile', file );
        return getResult.data.result;
    }

    public async getImage(id: number): Promise<FileUpload> {
        let getResult = await http.get('api/services/app/WorkOrder/GetFile?id=' + id);
        return getResult.data.result;
    }
 
    public async getFiles(id: number, type: number): Promise<FileUpload[]> {
        let getFilesResult = await http.get('api/services/app/WorkOrder/GetEntityFiles?id=' + id + '&type=' + type);
        return getFilesResult.data.result;
    }
  
    public async commitEntityFiles(input: EntityCommitContainer): Promise<boolean> {
        let getResult = await http.post('api/services/app/WorkOrder/CommitEntityFiles', input);
        return getResult.data.result;
    }
    
    //------------------------------------------------------------------

    public async getNotes(askDto: WorkOrderAskDto): Promise<WorkOrderSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, WorkOrderEntitySummaryDto, WorkOrderTypeSummaryDto, WorkOrderTemplateSummaryDto, WorkOrderStateSummaryDto, WorkOrderOptionSummaryDto, WorkOrderItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto>> {
        let getRecordsResult = await http.get('api/services/app/WorkOrder/GetNotes', { params: askDto });
        return getRecordsResult.data.result;
    }

    public async addNotes(askDto: WorkOrderCreateDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<WorkOrderReturnDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {

        let getRecordsResult = await http.post('api/services/app/WorkOrder/AddNotes', askDto);
        return getRecordsResult.data.result;
    }

    public async deleteNotes(askDto:  WorkOrderCreateDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<WorkOrderReturnDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getRecordsResult = await http.post('api/services/app/WorkOrder/DeleteNotes', askDto);
        return getRecordsResult.data.result;
    }

    //------------------------------------------------------------------

    public async getActionSummary(askDto: WorkOrderAskDto): Promise<ActionSummaryDto<UserActionRecordDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Records/GetUserActivitySummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }
}

export default new WorkOrderService();
